import React from "react";
import { useEffect, useState } from "react";
import * as events from "./constants";
import { TRACKING_BOT, CTA_ID, YouTubePlaybackStatus, Name, Category } from "../analytics/constants";

declare global {
  interface Window {
    analytics: any;
    dataLayer: any;
    gaGlobal: any;
    OnetrustActiveGroups: any;
    gtag: any;
  }
}

// Hook for calling segment, events and helpers
export const useSegment = (): any => {
  const [segment, setSegment] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const helpers = {
    delayTrack: async (name: string, props: any) => {
      return new Promise((resolve, reject) => {
        try {
          resolve(trackView(name, props));
        } catch (error) {
          reject(error);
        }
      });
    }
  };

  useEffect(() => {
    const fetchSegment = async function () {
      try {
        setLoading(true);
        const analytics = await window?.analytics;
        if (analytics !== undefined) {
          setSegment(analytics);
        }
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    };
    fetchSegment();
  }, []);

  return { segment, events, loading, helpers };
};


/* TRACKING: LOGIC */
// if (window["OneTrust"]) window["OneTrust"]?.ToggleInfoDisplay();
// document.cookie.match("(^|;)\\s*" + cname + "\\s*=\\s*([^;]+)")?.pop() || ""

// Returns true if the browser is a bot
export const isWebCrawler = () => {
  const userAgent = window?.navigator?.userAgent.toLowerCase();
  return !TRACKING_BOT.every(element => !userAgent.includes(element));
};

// Add "text/plain" and optanon-category-C000X if cookieGroup is set
export const scriptLoader = (data: any, cookieGroup = "") => {
  const snippet = cookieGroup
  ? `<script async type="text/plain" class="optanon-category-C000${cookieGroup}">${data}</script>`
  : `<script async>${data}</script>`
  document.head.appendChild(document.createRange().createContextualFragment(snippet));
};

// window.gtag("get", "G-4V17FFVS2W", "client_id", (cid) => console.log(cid))
export const trackingReady = (f: any, ...props: any) => {
  const clientId = new Promise(resolve => {
    window.gtag && window.gtag("get", "G-4V17FFVS2W", "client_id", resolve)
  });
  clientId?.then((cid) => f(cid, ...props))
};

/*
Disable default GA4 page_view:
gtag("config", "${TRACKING_ID.GoogleAnalytics}", {send_page_view: false}) in constants
GA4 admin > Data Streams > Web stream details > Enhanced measurement > Configure > Page Views > Show advanced settings > disable "Page changes based on browser history events"
*/
export const pageView = (clientId: any, location: any, prevLocation: any) => {
  const name = "Page Viewed";
  const defaults = {
    name,
    clientId, 
    category: "Page View",
    url: document.location.href,
    search: document.location.search,
    referrer: prevLocation?.pathname || "",
    path: location?.pathname || document.location.pathname,
    title: `${document.location.pathname?.split("/").reverse()[1].replace(/-/g, " ")
    .replace(/(^\w|\s\w)/g, m => m.toUpperCase())} | Perpetua` || "" // document.title n/a
  }
  window?.analytics && window?.analytics.page(name, defaults); // Segment (UA)
  window?.gtag && window?.gtag("event", name, defaults);       // GA4
  let _hsq = window["_hsq"] = window["_hsq"] || [];
  _hsq.push(["trackPageView"]);                                // Hubspot
  console.log(history.length - 1, clientId);
};

export const eventCallGA4 = (clientId: any, name: string, props: any) => {
  window?.gtag && window?.gtag("event", name, {clientId: `"${clientId}"`, ...props});
};

export const trackView = (name: string, props: any) => {
  window?.analytics && window?.analytics.track(name, props);
  trackingReady(eventCallGA4, name, props)
};

export const marketingCookie = (clientId: string) => {
  if (document.cookie.indexOf("PerpetuaSessionData") !== -1) return;

  const channel = (() => {
    const ch = {
      "Paid Search": /gclid=/,
      "Paid Social": /(fbclid|li_fat_id)=/,
      "Affiliate": /(gspk=|utm_source=affiliate)/
    };
    const se = ["google", "bing", "yahoo", "baidu", "duckduckgo"];
    try { return Object.entries(ch).find(i => i[1].test(window.location.href))[0] }
    catch { return se.some(i => document.referrer.includes(i)) ? "Organic" : "Direct" }
  });

  const raw = {
    "acquisition_channel_source": channel(),
    "google_analytics_client_id": clientId,
    "partner_key": window["growsumo"]?.data.partner_key,
    "gclid": new URL(location.href).searchParams.get("gclid"),
    "fbclid": new URL(location.href).searchParams.get("fbclid"),
    "li_fat_id": new URL(location.href).searchParams.get("li_fat_id"),
    "utm_campaign": new URL(location.href).searchParams.get("utm_campaign"),
    "utm_content": new URL(location.href).searchParams.get("utm_content"),
    "utm_medium": new URL(location.href).searchParams.get("utm_medium"),
    "utm_source": new URL(location.href).searchParams.get("utm_source"),
    "utm_term": new URL(location.href).searchParams.get("utm_term"),
    "campaignid": new URL(location.href).searchParams.get("campaignid"),
    "adgroupid": new URL(location.href).searchParams.get("adgroupid"),
    "keyword": new URL(location.href).searchParams.get("keyword"),
  };

  const filtered = Object.fromEntries(Object.entries(raw).filter(([_, v]) => v != null));
  Object.keys(filtered).map(k => {
    if (!localStorage.getItem(k) && filtered[k]) localStorage.setItem(k, filtered[k]);
  });

  const cdomain = process.env.NODE_ENV === "production"
  ? (location.host.includes(".pages.dev") ? ".perpetua-home.pages.dev" : ".perpetua.io")
  : "127.0.0.1"
  document.cookie = `PerpetuaSessionData=${JSON.stringify(filtered)};domain=${cdomain};path=/`;
};

// developers.google.com/youtube/iframe_api_reference
// setTimeout(() => onYouTubeIframeAPIReady(src), 500);
// add optanon-category-C0001 ot-vscat-C0001 to iframe div className
export const onYouTubeIframeAPIReady = (id: any) => {
  let player: any;
  player = new window["YT"].Player("yt-player", {
    videoId: id,
    width: "100%",
    height: "100%",
    playerVars: {
      playsinline: 1,
      origin: window.location.host,
    },
    events: {
      // onError: onPlayerError,
      onReady: timestampReached, // watched % (25, 50, 75, 100)
      onStateChange: stateChange // check if playback started + send playback events
    }
  });

  // event logic
  let playbackStarted = 0;
  function stateChange(event: any) {
    if (event.data == window["YT"].PlayerState.PLAYING && playbackStarted === 0) playbackStarted = 1

    if (Object.keys(YouTubePlaybackStatus).includes(event.data.toString()))
      trackView(Name.VideoPlayback, {
        category: Category.YouTubeEvents,
        label: YouTubePlaybackStatus[event.data]
      });
  };

  function timestampReached(event: any) {

    const interval = (event: any, timestamp: Number) => {
      let timer = setInterval(function() {
        let watched = (event.target.getCurrentTime() / event.target.getDuration()) * 100
        if (watched >= timestamp) {
          timestamp = timestamp === 99 ? 100 : timestamp;
          trackView(Name.VideoPlayback, {
            category: Category.YouTubeEvents,
            label: `${timestamp}% watched`
          });
          clearInterval(timer);
        }
      }, 1000);
    };
    
    [25, 50, 75, 99].map(i => interval(event, i));
  };

};


/* TRACKING: EVENTS */

export const ctaClickEvent = () => {
  let ctas = Object.values(document.querySelectorAll("button"));
  ctas.map(i => i.onclick = () => Object.values(CTA_ID).map(j => {
    if (i.hasAttribute("id") && i.getAttribute("id")!.endsWith(j.id))
      trackView(Name.Cta, {category: "CTA events", label: j.event});
  }))
};

// paidSocialEvent("lintrk", PaidSocialEvents.GetStartedFlow.Linkedin.ButtonClick)
export const paidSocialEvent = (platform: string, id: string) => {
  if (window[platform]) window[platform]("track", platform === "lintrk" ? { conversion_id: id } : id);
};

// Used to set correct Original Source in contact props
export const HubspotEvent = (email: any, event: any) => {
  let _hsq = window["_hsq"] = window["_hsq"] || [];
  _hsq.push(["identify", {email: email}]);
  _hsq.push(["trackEvent", {id: event}]);
};

// Send paid social events on Get Started CTA click
export const getStartedCtaClick = () => {
  document.querySelectorAll("button[id$='-getstarted']").forEach(
    x => (x.onclick = function () {
      paidSocialEvent("lintrk", events.PaidSocialEvents.GetStartedFlow.Linkedin.ButtonClick);
      paidSocialEvent("fbq", events.PaidSocialEvents.GetStartedFlow.Facebook.ButtonClick);
    })
  );
};

// Used to pass URL params between domain and subdomain
export const appendTrackingParams = (url: any) => {
  let params = document.cookie.match("(^|;)\\s*PerpetuaSessionData\\s*=\\s*([^;]+)")?.pop();
  if (!params) return;
  let obj = {};
  for (let [k, v] of Object.entries(JSON.parse(params))) {
    if (/fbclid|li_fat_id|gclid|utm_/.test(k)) obj[k] = v;
  }
  obj = Object.keys(obj).map(i => i + "=" + obj[i]).join("&");
  return obj ? url + (url.includes("?") ? "&" : "?") + obj : url;
};
