module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-embedder","id":"29a53549-09c0-5d3a-8a1d-e29b733288ee","name":"gatsby-remark-embedder","version":"6.0.1","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-embedder/dist/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-240,"duration":200},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-remark-lottie/gatsby-browser.js'),
      options: {"plugins":[],"generatePlaceholders":true,"lottieVersion":"5.7.1","renderer":"svg","loop":true,"autoplay":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Perpetua","short_name":"Perpetua","start_url":"/","background_color":"#ffffff","theme_color":"#6366F1","display":"standalone","icon":"src/images/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cee08aae1931c311bc56386d0be6f346"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en-US","de","ja"],"defaultLanguage":"en-US","redirect":false,"trailingSlash":"always","siteUrl":"https://perpetua.io","i18nextOptions":{"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/blog","languages":["en-US","de"]},{"matchPath":"/:lang?/blog/category/:uid","getLanguageFromPath":true,"exclude":["ja","zh-CN"]},{"matchPath":"/:lang?/(.*)blog-(.*)","getLanguageFromPath":true,"exclude":["ja","zh-CN"]},{"matchPath":"/:lang?/blog/search/","languages":["en-US"]},{"matchPath":"/amazon-advertising-benchmark-tool","languages":["en-US","de"]},{"matchPath":"/affiliate-program","languages":["en-US"]},{"matchPath":"/prism","languages":["en-US"]},{"matchPath":"/refer-a-seller","languages":["en-US"]},{"matchPath":"/sellics-joins-perpetua","languages":["en-US","de"]},{"matchPath":"/amazon-seo-tool","languages":["en-US","de"]},{"matchPath":"/amazon-profit-calculator","languages":["en-US","de"]},{"matchPath":"/best-helium-10-alternative","languages":["en-US"]},{"matchPath":"/amazon-ppc-software-sponsored-ads-management-tool","languages":["en-US","de"]},{"matchPath":"/vendor-edition","languages":["en-US"]},{"matchPath":"/sonar-amazon-keyword-tool","languages":["en-US","de"]},{"matchPath":"/resources","languages":["en-US"]},{"matchPath":"/:lang?/resources/:uid","languages":["en-US"]},{"matchPath":"/:lang?/resources/guides/:uid","languages":["en-US"]},{"matchPath":"/:lang?/resources/reports/:uid","languages":["en-US"]},{"matchPath":"/:lang?/privacy","languages":["en-US","de","ja"]},{"matchPath":"/:lang?/tos","languages":["en-US","de","ja"]},{"matchPath":"/:lang?/impressum","languages":["de"]},{"matchPath":"/:lang?/","languages":["en-US","de","ja"]},{"matchPath":"/:lang?/:uid","languages":["en-US"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":1,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","trailingSlashes":true,"crumbSeparator":" > "},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
