// import { StrictMode } from "react";
// gatsbyjs.com/docs/reference/built-in-components/gatsby-script/
import RootElement from './src/components/RootElement';
import React from "react";
require("./src/styles/global.scss");
const { TRACKING_LIB } = require("./src/utils/analytics/constants");

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};

const {
  isWebCrawler,
  scriptLoader,
  trackingReady,
  pageView,
  marketingCookie,
  ctaClickEvent,
  getStartedCtaClick
} = require("./src/utils/analytics/index");

export const onClientEntry = () => {}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (isWebCrawler()) return;

  if (!prevLocation) {
    setTimeout(TRACKING_LIB.CookiePro);

    scriptLoader(TRACKING_LIB.Partnerstack);
    scriptLoader(TRACKING_LIB.Segment, "2");
    scriptLoader(TRACKING_LIB.GoogleOptimize, "2");
    scriptLoader(TRACKING_LIB.GoogleAnalytics, "2");
    scriptLoader(TRACKING_LIB.Intercom, "3");
    scriptLoader(TRACKING_LIB.Linkedin, "4");
    scriptLoader(setTimeout(TRACKING_LIB.Facebook, 2000), "4");
    scriptLoader(TRACKING_LIB.Hotjar,"2");
    scriptLoader(TRACKING_LIB.Hubspot,"2");
    scriptLoader(TRACKING_LIB.Youtube);

    scriptLoader(`function OptanonWrapper() {
      if (window?.OnetrustActiveGroups === ",C0001,") return;
      const trackingReady = ${trackingReady};
      const pageView = ${pageView};
      const marketingCookie = ${marketingCookie};
      trackingReady(marketingCookie);
      trackingReady(pageView);
    }`);
  } else {
    trackingReady(pageView, location, prevLocation);
  }

  getStartedCtaClick();
  ctaClickEvent();
};